import { Stack } from "react-bootstrap";
import { CarouselImgCard } from "./CarouselImgCard";
import { PhotoData } from "../data/PhotosData";

export const PhotosStack = ({category}) => { 

    const { title, cards } = PhotoData[category]; 

    return ( 
        <div className="PS-category"> 
            <h3>{title}</h3>
            <Stack direction="horizontal" gap={5}> 
                { 
                    cards.map((card, index) => (

                        <CarouselImgCard header={card.header} title={card.title} img_urls={card.img_urls} imgURLs={card.imgURLs} ID={card.id} footer={card.footer} />

                    ))
                }
            </Stack>
        </div>

    );

};