import React from "react";
import { Container} from 'react-bootstrap'; 
import { PhotosStack } from "./subComponents/PhotosStack";




export const Photos = () =>  { 


    return ( 
        <section className="photos" id="photos"> 
            <Container> 
                <PhotosStack category='comp'     />
                <PhotosStack category='research' /> 
                <PhotosStack category='travel'   /> 
            </Container>
        </section>

    ); 
}