import React from 'react';
import { Projects } from '../components/Projects';


export const Experience = () => {
    return (
        <div className='experience'>
            <Projects/>
        </div>
    );
}
