import { Col, Container, Row } from "react-bootstrap/";

export const AboutMe = () =>  { 

    return (

        <section className="aboutme" id="aboutme">
            <Container>
                <Row className="align-items-start">
                    <Col xs={12} md={6} xl={5}> 
                        <div className="headshot">
                            <img src='./assets/headshot3.png' alt='my headshot' />
                        </div>
                    
                    </Col>
                    <Col xs={12} md={6} xl={7}>
                        <div className="col2">
                            <div className="tagline-container">
                                <span className="tagline">About Me!</span>
                            </div>
                            <div className="info"> 
                                <div className="label">Major:</div>
                                <div className="value">Physics</div>
                            </div>
                            <div className="info"> 
                                <div className="label">Minors:</div>
                                <div className="value">Computer Science, Mathematics</div>
                            </div>
                            <div className="info"> 
                                <div className="label">Institution:</div>
                                <div className="value">Oklahoma State Univeristy</div>
                            </div>
                            <div className="info"> 
                                <div className="label">Employed:</div>
                                <div className="value">OKSTATE IT</div>
                            </div>

                            <p>I have a questionably large amount of interests, and am often best described as a jack of all trades. However, for the sake of summarization, I like to think of my self as an <i>artist</i> who loves to problem solve. I rarely turn down the opportunity to make some process more efficient, time permitting. My love for computers fuels my passion for Machine Learning, and High Energy Particle Physics. As I always say:  </p>
            
                            <p className="quote">" You Can Make A <span className="theme-blue-text">Good</span> Idea a <span className="orange-text">Great</span> Idea By Having It Run At A Million Instructions Per Second "</p>

                        </div>

                    </Col>



                </Row>
            </Container>


        </section>
    );
}