import { Modal, Carousel } from "react-bootstrap";


export const PhotosModal = ({ Header, show, handleClose, imgURLs }) => { 

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="photos-modal" data-bs-theme="dark" fullscreen>
            <Modal.Header closeButton='true'> 
                <Modal.Title>PhotoViewer - {Header}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="photos-modal-body">
                <Carousel interval={null}> 
                    {imgURLs.map((imgURL, index) => ( 
                        <Carousel.Item key={index}> 
                            <img className="d-block w-100 photos-modal-image" src={imgURL} alt={`Slide ${index}`} />
                        </Carousel.Item>
                    ))
                    }
                </Carousel>
            </Modal.Body>
        </Modal>
    );


}