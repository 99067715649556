const img_path = './img/proj'

export const ProjectsData = { 

    PHYS: 
    { 
        title: "Physics",
        csstype: 'phys-type',
        cards:
        [ 
            { 
                title:  "Smart Water",
                id:     "proj_smartwater", 
                text:   "Researched and developed as apart of my participation in the International Physicists' Tournament.",
                imgURL: `${img_path}/proj_smartwater.jpg`   

            },
            { 
                title:  "Big Bang",
                id:     "proj_bigbang", 
                text:   "Researched and developed as apart of my participation in the International Physicists' Tournament.",
                imgURL: `${img_path}/proj_bigbang.jpg`   

            }
               
        ]

    },

    CS: 
    { 
        title: "CS",
        csstype: 'cs-type',
        cards:
        [ 
            { 
                title:  "MyDesk",
                id:     "proj_smartwater", 
                text:   "3D Modeling Project I picked up to learn and practice working with three.js framework. Still in construction.",
                imgURL: `${img_path}/proj_mydesk.jpg`,   
                exLink: 'https://vincent-may.com/MyDesk'

            },
               
        ]

    },


    OTHER: 
    { 
        title: "Other",
        csstype: 'other-type',
        cards:
        [ 
            { 
                title:  "FPV Drone",
                id:     "proj_smartwater", 
                text:   "First Drone I ever built. Worked with a close friend to climb the steep hill of FPV engineering and flying.",
                imgURL: `${img_path}/proj_drone.jpg`   

            },
               
        ]

    }


}




// PHYS: 
// { 
//     title: "",
//     cards:
//     [ 
//         { 
//             title:  "",
//             id:     "", 
//             text:   "",
//             imgURL: "",   

//         }

//     ]

// }