import React from 'react';
import { ContactMe } from '../components/ContactMe';

export const Connect = () => {
    return (
        <div className='connect'>
            <ContactMe/>
        </div>
    );
}

