import { useState, useEffect} from 'react'; 
import { Col, Container, Row } from "react-bootstrap/"; 
import { useNavigate } from 'react-router-dom';




export const Banner = () => { 

    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(300 - Math.random() * 100);
    const toRotate = ["Physics | Computer Science | Math | Data Science | Engineering"
 ];
    const period = 1000;


    const tick = () => {
      let i = loopNum % toRotate.length;
      let fullText = toRotate[i];
      let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);
  
      setText(updatedText);
  
      if (isDeleting) {
        setDelta(prevDelta => prevDelta / 2);
      }
  
      if (!isDeleting && updatedText === fullText) {
        setIsDeleting(true);
        setDelta(period);
      } else if (isDeleting && updatedText === '') {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
        setDelta(500);
      } 
    }
  
    useEffect(() => {
      let ticker = setInterval(() => {
        tick();
      }, delta);
  
      return () => { clearInterval(ticker) };

    }, [text])


    const navigate = useNavigate();

    const handleNavClick = () => {
        navigate("/connect", { replace: true });

        ////Uncomment if there is ever more than one section on the connect page
        // setTimeout(() => {
        //     const element = document.getElementById(section);
        //     if (element) {
        //         element.scrollIntoView({ behavior: 'smooth' });
        //     }
        // }, 100); // Delay to ensure navigation happens first
    };

  

    return( 
    <section className="banner" id="home"> 
        <Container>
            <Row className='align-items-center'>
                <Col xs={12} md={6} xl={7}> 
                    <span className="tagline">Hello World! Welcome to MyPage!</span>
                    <h1>I'm <span className='theme-blue-text'>Nathan May</span>!</h1>
                    <span className="subjects">{text}</span>
                    <p>I'm happy to see you made it to MyPage! MyPage was designed to house all of my interests, accomplishments, and progress throughout my career. I hope you enjoy, and please feel free to reach out! Don't forget to check out <a href="/exp#projects#proj_mydesk">MyDesk</a>!</p>
                    <button className='navButton' onClick={handleNavClick}><span>Want to Connect?</span></button>
                </Col> 
                <Col xs={12} md={6} xl={5} id='col2'>
                  <div>
                    <img src='./assets/cartoonme.png' alt='cartoon of me' />
                  </div>
                </Col>


            </Row>
        </Container>
    </section>

    )

}