import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";



const ExternalLinkButton = ({exLink}) => {
    return (
      <Button
        variant="info"
        as="a"
        href={exLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        Explore
      </Button>
    );
  };
export const ProjectCard = ({title, text, imgURL, type, exLink=null}) => { 
    

    return (
        <Card className='mt-3 project-card '>
            <Card.Img src={imgURL} alt={"slide 1 of " + {imgURL}} className="card-img"/> 
            <Card.ImgOverlay className={`d-flex flex-column justify-content-center text-center img-ovly ${type}`}>
                <Card.Title>{title}</Card.Title>
                <Card.Text>{text}</Card.Text>
                {exLink && <ExternalLinkButton className='external-link-button' exLink={exLink} />}
            </Card.ImgOverlay>
            <Card.Body className={type}>
                <h4>{title}</h4>
            </Card.Body>
        </Card>

    ); 
}