
import React from 'react';
import { Banner } from '../components/Banner';
import { AboutMe } from '../components/AboutMe';

export const Home = () => {
    return (
        <div className='home'>
            <Banner/>
            <AboutMe/>
        </div>
    );
}

