const img_path = './img/webp'

export const PhotoData = { 

    comp : 
    { 
        title: 'Competitions', 
        cards: 
        [   
            { 
                header: 'USPT 2023', 
                id:     'phot_uspt',
                title:  'Stillwater, OK', 
                footer: 'United States Physicist Tournament',
                imgURLs: 
                [ 
                    `${img_path}/USPT231.webp`, 
                    `${img_path}/USPT232.webp`,
                    `${img_path}/USPT233.webp`,
                ],
                img_urls:
                [
                    `${img_path}/USPT231.webp`, 
                    `${img_path}/USPT232.webp`,
                    `${img_path}/USPT233.webp`,
                    `${img_path}/USPT234.webp`,
                    `${img_path}/USPT235.webp`,
                    `${img_path}/USPT236.webp`,
                ]
            }, 
            // { 
            //     header: 'IPT', 
            //     id:     'phot_ipt',
            //     title:  'Zürich, Switzerland', 
            //     footer: 'International Physicists Tournament',
            //     imgURLs: 
            //     [ 
            //         ''
            //     ],
            //
            // }, 
            { 
                header: 'DATATHON 2024', 
                id:     'phot_datathon',
                title:  'OKSTATE', 
                footer: 'Oklahoma State U. 2024 Datathon',
                imgURLs: 
                [ 
                    `${img_path}/DTHN241.webp`,
                    `${img_path}/DTHN242.webp`,
                ],
                img_urls:
                [
                    `${img_path}/DTHN241.webp`,
                    `${img_path}/DTHN242.webp`,
                ]
            }
        ]

    },


    research : 
    { 
        title: 'Research & Conferences', 
        cards: 
        [   
            { 
                header: 'SAEOPP Conf. 2024', 
                id:     'phot_saeopp2024',
                title:  'Atlanta, GA', 
                footer: 'SAEOPP 2024 Research Conference',
                imgURLs: 
                [ 
                    `${img_path}/saeopp1.webp`, 
                    `${img_path}/saeopp2.webp`, 
                    `${img_path}/saeopp3.webp`, 
                ],
                img_urls:
                [
                    `${img_path}/saeopp1.webp`, 
                    `${img_path}/saeopp2.webp`, 
                    `${img_path}/saeopp3.webp`, 
                    `${img_path}/saeopp4.webp`
                ]
            }
        ]
    },

    travel : 
    { 
        title: 'Travel', 
        cards: 
        [   
            { 
                header: 'CERN 2024', 
                id:     'phot_cern2024',
                title:  'Geneva, Switzerland', 
                footer: 'Visit to the ATLAS Detector',
                imgURLs: 
                [ 
                    `${img_path}/CERN1.webp`, 
                    `${img_path}/CERN2.webp`, 
                    `${img_path}/CERN3.webp`
                ],
                img_urls:
                [
                    `${img_path}/CERN1.webp`, 
                    `${img_path}/CERN2.webp`, 
                    `${img_path}/CERN3.webp`
                ]
            } 
        ]

    },

    // hobbies: 
    // {
    //     title: 'Hobbies', 
    //     cards: 
    //     { 
    //         header: '', 
    //         title: '', 
    //         imgUrls: 
    //         [ 
    //             ''
    //         ]
    //     }

    // }



}