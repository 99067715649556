import { useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import emailjs from '@emailjs/browser'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




export const ContactMe = () =>  { 

    const good_notify = () => {
        toast("Thank You!! VMPS will deliver shortly!", {
            type:"success",
            icon: "🚀",
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
    const bad_notify = () => {
        toast("Mail Failed to Send. VMPS must be O.O.O.", { 
            position: "bottom-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }

    const form = useRef(); 
    const [subButtText,setSubButtText ] = useState('Send');


    const handleSubmit = async (e) => { 

        e.preventDefault(); 
        setSubButtText("Sending...");

        emailjs
        .sendForm('service_69d1b3a', 'template_oqe06q5', form.current, {
          publicKey: 'HS167kkiIE8sZv4HI',
        })
        .then(
          () => {
            console.log('Successfully sent email!');
            good_notify();
            form.current.reset();

            const emailShip = document.getElementById('email-ship');
            emailShip.classList.toggle('shipping');
            emailShip.addEventListener('animationend', function() {
                emailShip.remove();
            });

            const myForm = document.getElementById('contact-form');
            const inputs = myForm.querySelectorAll('input');
            inputs.forEach(input => input.remove());

            const sendB = document.getElementById('send-button'); 
            sendB.remove(); 
            

            const tarea = document.getElementById('ta');
            tarea.placeholder = 'Thank you for shipping with VMPS. Please allow a few days for a response. \n\nDon\'t worry, we handle postage!';
          },
          (error) => {
            console.log('Failed to send email: ', error.text);
            bad_notify();
          },
        );

        setSubButtText('Send')

    }
    return ( 

        <section className="contact" id="contact"> 
            <Row className="align-items-center"> 
                <Col md={6}>
                    <div className="col1">
                        <div className="tagline-container">
                            <span className="tagline">Let's Connect!</span>
                        </div>
                        <form ref={form} onSubmit={handleSubmit} id="contact-form">
                            <Row> 
                                <Col sm={6} className="px-1"> 
                                    <input type="text" name="ufirst_name" placeholder="First Name" />
                                </Col>
                                <Col sm={6} className="px-1"> 
                                    <input type="text" name ="ulast_name" placeholder="Last Name Name" />
                                </Col>
                                <Col sm={6} className="px-1"> 
                                    <input type="email" name="uemail" placeholder="Email Address" />
                                </Col>
                                <Col sm={6} className="px-1"> 
                                    <input type="tel" name="uphone_number" placeholder="Phone Number" />
                                </Col>
                                <Col> 
                                    <textarea id='ta' name="message" type='text' rows={6} placeholder="Message" />
                                    <button id="send-button" type="submit"><span>{subButtText}</span></button>
                                </Col>
                            </Row>
                        </form>
                    </div>

                </Col>
                <Col md={6}>
                    <div className="rocket-container"> 
                        <img  className="shipImg" id="email-ship" src="./assets/mail-ship.png" alt="Rocket Ship"/>
                    </div>
                </Col>
            </Row>

        </section>

    ); 

}