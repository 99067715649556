import React, { useState } from "react";
import { PhotosModal } from "./PhotosModal";
import { Card, Carousel } from "react-bootstrap";


export const CarouselImgCard = ({header, title, imgURLs, img_urls, ID,footer=null, t=null}) => { 

    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return ( 
        <Card id={ID} style={{width: '20%'}} border="info" className="CI-Card"> 
            <Card.Header>{header}</Card.Header>
                <Carousel interval={t}> 
                    { 
                        imgURLs.map((url,index) => (
                            <Carousel.Item key={index}> 
                                <img
                                    className="d-block w-100"
                                    src={url}
                                    alt={`Slide ${index + 1}`}
                                
                                />

                            </Carousel.Item>
                        ))
                    }
                </Carousel>
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                {footer ? <Card.Text>{footer}</Card.Text> : null}
            </Card.Body>
            <Card.Footer>
                <button className="btn btn-info" onClick={handleOpenModal}>
                    More
                </button>
            </Card.Footer>
            <PhotosModal Header={header} show={showModal} handleClose={handleCloseModal} imgURLs={img_urls}/>
        </Card>

    );

};
