
import './App.css';
import React, { useEffect } from 'react';
import { NavBar } from './components/NavBar';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Home } from './pages/Home';
import { Connect } from './pages/Connect';
import { Album } from './pages/Album';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Experience } from './pages/Experience';

function ScrollToSection() {
  const location = useLocation();

  useEffect(() => {
      if (location.hash) {
          const element = document.getElementById(location.hash.substring(1));
          if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
          }
      }
  }, [location]);

  return null;
}


document.addEventListener('contextmenu', function (e) {
  if (e.target.tagName === 'IMG') {
    e.preventDefault();
  }
}, false);



function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <NavBar />
        <ScrollToSection />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/connect" element={<Connect />} />
          <Route path='/photos' element={<Album />} />
          <Route path='/exp' element={<Experience />}/>
        </Routes>
      </Router>

    </div>
  );
}

export default App;
