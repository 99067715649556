import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { useState , useEffect} from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';

export const NavBar = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50){ 
                setScrolled(true); 
            }else{ 
                setScrolled(false); 
            }
        }

        window.addEventListener("scroll", onScroll); 


        return () => window.removeEventListener("scroll", onScroll)
    }, [])

    const onUpdateActiveLink = (link_name) => { 
        setActiveLink(link_name)
    }

  return (
    <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
      <Container className='justify-content-end'> 
        <Navbar.Brand as={Link} to='/#home'>
            <img src='./N-Logo.png' alt='N_Logo'/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link  as={Link} to='/#home' className={activeLink === 'home' ? "active navbar-link":"navbar-link"} onClick={()=>onUpdateActiveLink('home')}>Home</Nav.Link>
            <Nav.Link  as={Link} to='/#aboutme' className={activeLink === 'aboutme' ? "active navbar-link":"navbar-link"} onClick={()=>onUpdateActiveLink('aboutme')}>AboutMe</Nav.Link>
            <Nav.Link  as={Link} to='/exp#projects' className={activeLink === 'projects' ? "active navbar-link":"navbar-link"} onClick={()=>onUpdateActiveLink('projects')}>Projects</Nav.Link>
            <Nav.Link  as={Link} to='/exp' className={activeLink === 'workhist' ? "active navbar-link":"navbar-link"} onClick={()=>onUpdateActiveLink('workhist')}>WorkHist</Nav.Link>
            <Nav.Link  as={Link} to='/#ref' className={activeLink === 'ref' ? "active navbar-link":"navbar-link"} onClick={()=>onUpdateActiveLink('ref')}>Ref</Nav.Link>
            <Nav.Link  as={Link} to='/photos' className={activeLink === 'photos' ? "active navbar-link":"navbar-link"} onClick={()=>onUpdateActiveLink('photos')}>Photos</Nav.Link>
            <Nav.Link  as={Link} to='/connect' className={activeLink === 'connect' ? "active navbar-link":"navbar-link"} onClick={()=>onUpdateActiveLink('connect')}>ContactMe</Nav.Link>
          </Nav>
          <Nav className='gradient-icons'>
            <Nav.Link href="https://www.linkedin.com/in/nathan-may-9614282b2/" target="_blank">
              <i className="bi bi-linkedin "></i>
            </Nav.Link>
            <Nav.Link href="https://join.skype.com/invite/eroBsYZmQD0C" target="_blank">
              <i className="bi bi-skype "></i>
            </Nav.Link>
            <Nav.Link href="https://www.instagram.com/_may_vincent/" target="_blank">
              <i className="bi bi-instagram"></i>
            </Nav.Link>
            <Nav.Link href="https://www.facebook.com/profile.php?id=100088090540275" target="_blank">
              <i className="bi bi-facebook " fill='currentColor' width={4}/>
            </Nav.Link>
            <Nav.Link  as={Link} to='/connect' onClick={()=>onUpdateActiveLink('connect')}>
              <i className="bi bi-rocket "></i>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

