import React from "react";
import { Tab, Nav, Container, Row, Col } from "react-bootstrap";
import { ProjectsData } from "./data/ProjectsData";
import { ProjectCard } from "./subComponents/ProjectCard";
import TrackVisibility from 'react-on-screen';
import 'animate.css'; 


export const Projects = () => { 

    return(
        <section className="projects" id="projects">
            <h1>Projects & Research</h1>
            <Container fluid>
                <Row>
                    <Col size={12}>
                        <TrackVisibility>
                            {({ isVisible }) =>
                            <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">All</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">PHYS</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">CS</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="fourth">Other</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__lightSpeedInRight" : ""}>
                                        <Tab.Pane eventKey='first'> 
                                            <Row>
                                                { 

                                                    ProjectsData['PHYS'].cards.map((project, index) => { 
                                                        const csstype = ProjectsData['PHYS'].csstype
                                                        return( 
                                                            <Col key={project.id} xs={12} sm={6} md={4} lg={3}>
                                                                <ProjectCard title={project.title} text={project.text} imgURL={project.imgURL} type={csstype} exLink={project.exLink}/>
                                                            </Col>
                                                        );
                                                    })

                                                }
                                                { 

                                                    ProjectsData['CS'].cards.map((project, index) => { 
                                                        const csstype = ProjectsData['CS'].csstype
                                                        return( 
                                                            <Col key={project.id} xs={12} sm={6} md={4} lg={3}>
                                                                <ProjectCard title={project.title} text={project.text} imgURL={project.imgURL} type={csstype} exLink={project.exLink} />
                                                            </Col>
                                                        );
                                                    })

                                                }
                                                { 

                                                    ProjectsData['OTHER'].cards.map((project, index) => { 
                                                        const csstype = ProjectsData['OTHER'].csstype
                                                        return( 
                                                            <Col key={project.id} xs={12} sm={6} md={4} lg={3}>
                                                                <ProjectCard title={project.title} text={project.text} imgURL={project.imgURL} type={csstype} exLink={project.exLink} />
                                                            </Col>
                                                        );
                                                    })

                                                }
                                            </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey='second'> 
                                            <Row>
                                                { 

                                                    ProjectsData['PHYS'].cards.map((project, index) => { 
                                                        const csstype = ProjectsData['PHYS'].csstype
                                                        return( 
                                                            <Col key={project.id} xs={12} sm={6} md={4} lg={3}>
                                                                <ProjectCard title={project.title} text={project.text} imgURL={project.imgURL} type={csstype} exLink={project.exLink} />
                                                            </Col>
                                                        );
                                                    })

                                                }
                                            </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey='third'> 
                                            <Row>
                                                { 

                                                    ProjectsData['CS'].cards.map((project, index) => { 
                                                        const csstype = ProjectsData['CS'].csstype
                                                        return( 
                                                            <Col key={project.id} xs={12} sm={6} md={4} lg={3}>
                                                                <ProjectCard title={project.title} text={project.text} imgURL={project.imgURL} type={csstype} exLink={project.exLink} />
                                                            </Col>
                                                        );
                                                    })

                                                }
                                            </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey='fourth'> 
                                            <Row>
                                                { 

                                                    ProjectsData['OTHER'].cards.map((project, index) => { 
                                                        const csstype = ProjectsData['OTHER'].csstype
                                                        return( 
                                                            <Col key={project.id} xs={12} sm={6} md={4} lg={3}>
                                                                <ProjectCard title={project.title} text={project.text} imgURL={project.imgURL} type={csstype} exLink={project.exLink} />
                                                            </Col>
                                                        );
                                                    })

                                                }
                                            </Row>
                                        </Tab.Pane>                                  
                                    </Tab.Content>
                                </Tab.Container>
                            </div>}
                        </TrackVisibility>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
